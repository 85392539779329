body {
  // background-color: var(--cui-tertiary-bg);
  // color: var(--cui-text-color);
}

.wrapper {
  width: 100%;
  @include ltr-rtl("padding-left", var(--cui-sidebar-occupy-start, 0));
  @include ltr-rtl("padding-right", var(--cui-sidebar-occupy-end, 0));
  will-change: auto;
  @include transition(padding .15s);
}

// .header > .container-fluid,
// .sidebar-header {
//   min-height: calc(3.5rem + 1px); // stylelint-disable-line function-disallowed-list
// }

.sidebar-brand-full {
  margin-left: 3px;
}

.sidebar-header {
  .nav-underline-border {
    --cui-nav-underline-border-link-padding-x: 1rem;
    --cui-nav-underline-border-gap: 0;
  }

  .nav-link {
    display: flex;
    align-items: center;
    min-height: calc(4rem + 1px); // stylelint-disable-line function-disallowed-list
  }
}

.sidebar-toggler {
  @include ltr-rtl("margin-left", auto);
}

.sidebar-narrow,
.sidebar-narrow-unfoldable:not(:hover) {
  .sidebar-toggler {
    @include ltr-rtl("margin-right", auto);
  }
}

.header > .container-fluid + .container-fluid {
  min-height: 3rem;
}

.footer {
  min-height: calc(3rem + 1px); // stylelint-disable-line function-disallowed-list
}

@if $enable-dark-mode {
  @include color-mode(dark) {
    body {
      background-color: var(--cui-tertiary-bg)!important ;
      color: var(--cui-text-color);
    }

    .footer {
      --cui-footer-bg: var(--cui-body-bg);
    }
  }
}

// [data-theme='light'] {
//   --cui-tertiary-bg: #f8f9fa;
//   --cui-body-bg: #ffffff;
//   --cui-text-color: #212529;
// }

// [data-theme='dark'] {
//   --cui-tertiary-bg: #081028;
//   --cui-body-bg: #141d2b;
//   --cui-text-color: #ffffff;
// }

// [data-theme='neon'] {
//   --cui-tertiary-bg: #081028;
//   --cui-body-bg: #020202;
//   --cui-text-color: #ffffff;
// }
